import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Card from "../../components/shared/card"
import Grid from "../../components/shared/grid"

export default function Jednodjelne() {
  return (
    <Layout>
      <SEO title="Aktuelno" />
      <h1 style={{ textAlign: "center" }}>Aktuelno</h1>
      <StaticQuery
        query={fetchProducts}
        render={data => (
          <Grid>
            {data.allSanityProduct.nodes.map(product => {
              let link
              if (product.categories[0].parents.length > 0) {
                link = `/${product.categories[0].parents[0].slug.current}/${product.categories[0].slug.current}/${product.slug.current}`
              } else {
                link = `/${product.categories[0].slug.current}/${product.slug.current}`
              }
              return (
                <Link key={product.defaultProductVariant.sku} to={link}>
                  <Card
                    text={product.title}
                    src={
                      product.defaultProductVariant.images.asset.fluid.srcWebp
                    }
                  />
                </Link>
              )
            })}
          </Grid>
        )}
      />
    </Layout>
  )
}

export const fetchProducts = graphql`
  query {
    allSanityProduct(
      filter: { tags: { eq: "aktuelno" } }
      sort: { fields: title, order: DESC }
    ) {
      nodes {
        title
        slug {
          current
        }
        defaultProductVariant {
          sku
          images {
            asset {
              fluid(maxWidth: 500) {
                srcWebp
              }
            }
          }
        }
        categories {
          slug {
            current
          }
          parents {
            slug {
              current
            }
          }
        }
      }
    }
  }
`
